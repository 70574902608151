import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import Ratio from '../../img/ratio.jpg';

const Wrapper = styled(Link)`
    width: 25%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    border: solid 2px #fff;
    @media (max-width: 980px) {
        width: 50%;
    }
    @media (max-width: 320px) {
        width: 100%;
    }
`;
const ImgHolder = styled.div`
    width: 100%;
    height: 100%;
    background: url(${(props) => props.src});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 1s;
    &:hover {
        transform: scale(1.2);
    }
    @media (max-width: 980px) {
        max-height: 180px;
    }
    @media (max-width: 650px) {
        max-height: 150px;
    }
`;

const Img = styled.img`
    width: 100%;
    height: auto;
    display: block;
    visibility: hidden;
    @media (min-width: 320px) {
        aspect-ratio: 1;
    }
`;
const InnerWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Overlay = styled.div`
    background: none;
`;
const Title = styled.p`
    padding: 0;
    margin: 0;
    text-align: center;
    font-family: ${(props) => props.theme.fonts.heading};
    font-weight: 700;
    font-size: 1.8em;
    max-width: 90%;
    line-height: 1em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.white};
    text-shadow: 1px 0 #000;
    @media (max-width: 640px) {
        font-size: 1.3em;
    }
    @media (max-width: 430px) {
        font-size: 1.3em;
    }
`;

const Tile = ({ src, children, link }) => (
    <Wrapper to={link}>
        <ImgHolder src={getSrc(src)}>
            <Img src={Ratio} alt={children} />
            <InnerWrapper>
                <Overlay>
                    <Title>{children}</Title>
                </Overlay>
            </InnerWrapper>
        </ImgHolder>
    </Wrapper>
);

export default Tile;
