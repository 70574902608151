import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    padding: 10px;
    max-width: 100%;
    width: 100%;
    background: ${({ theme }) => theme.colors.darkGray};
`;
const VideoWrapper = styled.div`
    max-width: 1200px;
    padding: 20px;
    margin: auto;
`;

const VideoContainer = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
`;

const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const SingleVideo = ({ videoId, ...otherProps }) => {
    return (
        <Wrapper {...otherProps}>
            <VideoWrapper>
                <VideoContainer>
                    <Iframe
                        src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0`}
                        frameborder="0"
                    ></Iframe>
                </VideoContainer>
            </VideoWrapper>
        </Wrapper>
    );
};

export default SingleVideo;
