import * as React from "react";
import { graphql } from 'gatsby';
import SiteWrapper from '../components/siteWrapper';
import Header from '../components/home/header';
import Neuigkeiten from '../components/news';

const IndexPage = (props) => (
  <SiteWrapper>
    <Header abteilungen={props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter.abteilungen} />
    <Neuigkeiten limit />
  </SiteWrapper>
)

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    allFile(filter: {sourceInstanceName: {eq: "sites"}, name: {eq: "home"}}) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              abteilungen {
                bild {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                title
                link
              }
            }
          }
        }
      }
    }
  }
`
