import React from 'react';
import styled from 'styled-components';
import Tile from './headerTile';
import { LinkButton } from '../common';
import SingleVideo from '../youtube/singleVideo';

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
`;
const HeaderLinks = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    padding: 30px 0 0 0;
    max-width: 600px;
    margin: auto;
    @media (max-width: 330px) {
        flex-direction: column;
    }
`;
const L = styled(LinkButton)`
    padding: 0 10px;
    font-size: 1.5em;
    margin: 5px 0;
`;

const Index = ({ abteilungen }) => {
    const renderTiles = () => {
        return abteilungen.map((abteilung) => (
            <Tile
                src={abteilung.bild}
                link={abteilung.link}
                key={abteilung.link}
            >
                {abteilung.title}
            </Tile>
        ));
    };

    return (
        <Wrapper>
            {renderTiles()}
            <HeaderLinks>
                <L to="/stellenanzeigen">Offene Stellen</L>
                <L to="/#news">News</L>
                <L to="/mitglied-werden">Mitglied werden</L>
            </HeaderLinks>
            <SingleVideo style={{ marginTop: 30 }} videoId="nOeG_G985pI" />
        </Wrapper>
    );
};

export default Index;
